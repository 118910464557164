<template>
  <div class="left-box">
    <div class="menu-icon" @click="openedStateChange" v-if="isCollapse">
      <el-icon v-if="isCollapse"><Expand /></el-icon>
    </div>
    <img src="@/assets/logo.png" style="width: auto;height: auto;filter: invert(100%);margin-left: 15px;" />
  </div>
  <div style="width: 100%;padding-right: 20px;">
    <div class="header-search">
      <header-search />
    </div>
    <div v-if="userdata" class="website-link">
      <el-link :href="'//'+getWebsiteLink()" target="_blank" :underline="false">
        {{getWebsiteLink()}}
      </el-link>
    </div>
    <i
        class="far fa-question-circle"
        aria-hidden="true"
    />
    <i
        class="fas fa-cogs"
        aria-hidden="true"
    />
<!--    <el-dropdown class="dropdown">-->
<!--      <i-->
<!--          class="fas fa-th-large"-->
<!--          aria-hidden="true"-->
<!--      />-->
<!--      <template #dropdown>-->
<!--        <el-dropdown-menu>-->
<!--          <el-dropdown-item>View</el-dropdown-item>-->
<!--          <el-dropdown-item>Add</el-dropdown-item>-->
<!--          <el-dropdown-item>Delete</el-dropdown-item>-->
<!--        </el-dropdown-menu>-->
<!--      </template>-->
<!--    </el-dropdown>-->
    <router-link to="/logout">
      <i class="fas fa-sign-out-alt" />
    </router-link>
    <span v-if="userdata">Welcome {{userdata.name}}</span>
  </div>
</template>

<script>
import { useStore } from "vuex";
import HeaderSearch from "../HeaderSearch/"
    export default {
        name: "HeaderHome",
      components: {
          HeaderSearch
      },
      mounted() {
          this.restoreUserData()
      },
      computed: {
        isCollapse() {
          return this.store.state.app.isCollapse
        }
      },
      data() {
          return {
            store: useStore(),
            user: null,
            userdata: null
          }
      },
      methods: {
        restoreUserData() {
          let userdata = JSON.parse(localStorage.getItem('userdata'))
          let user = JSON.parse(localStorage.getItem('user'))
          if(userdata) {
            this.user = user
            this.userdata = userdata[0]
          }
        },
        openedStateChange(){
          this.store.commit('app/isCollapseChange', !this.isCollapse)
        },
        getWebsiteLink() {
          if(this.userdata) {
            if(this.userdata.domains[0].config_custom_url1) {
              return  this.userdata.domains[0].config_custom_url1
            }
            return this.userdata.domains[0].domain
          }
          return null
        },
      },

    }
</script>

<style scoped>
.far, .fas {
  margin-right: 20px;
  color: #000;
}
.header-search {
  width:30%;
  position: relative;
  float: left;
  margin-left: 25%;
  overflow: auto;
  line-height: 1.5;
  margin-top: 10px;
  text-align: center;
}
.dropdown{
  margin-top:22px;
}
.left-box {
  height: 100%;
  display: flex;
  align-items: center;
}
.left-box .menu-icon {
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    font-weight: 100;
    cursor: pointer;
    margin-right: 10px;
    /*&:hover {*/
     /*background-color: var(--system-header-item-hover-color);*/
    /*}*/
    /*i {*/
    /*  color: var(--system-header-text-color);*/
    /*}*/
}
.website-link {
  max-width: 400px;
  float: left;
  margin-top: -10px;
  overflow: hidden;
  min-width: 190px;
}
</style>
